const jsonLdData = `
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Wenodo Hospitality Accountants",
  "alternateName": "Wenodo",
  "url": "https://www.wenodo.com",
  "logo": "https://www.wenodo.com/app-logo.a6333df9.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+44 20 8037 7768",
    "contactType": "customer service",
    "areaServed": "GB"
  },{
    "@type": "ContactPoint",
    "telephone": "+971 54 320 9768",
    "contactType": "customer service",
    "areaServed": "AE"
  }],
  "sameAs": [
    "https://www.instagram.com/wenodo_ltd",
    "https://www.linkedin.com/company/wenodo",
    "https://x.com/WenodoLTD",
    "https://www.facebook.com/people/Wenodo/61555965042636/?mibextid=LQQJ4d"
  ]
}

{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Wenodo Hospitality Accountants-London",
  "image": "https://www.wenodo.com/app-logo.a6333df9.png",
  "@id": "",
  "url": "https://www.wenodo.com",
  "telephone": "+44 20 8037 7768",
  "address": {
	"@type": "PostalAddress",
	"streetAddress": "Garden Suite, 96 Brighton Rd",
	"addressLocality": "Brighton",
	"postalCode": "SM7 1BU",
	"addressCountry": "GB"
  },
  "openingHoursSpecification": [
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Monday"
  	],
  	"opens": "08:00",
  	"closes": "18:00"
	},
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Tuesday"
  	],
  	"opens": "08:00",
  	"closes": "18:00"
	},
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Wednesday"
  	],
  	"opens": "08:00",
  	"closes": "18:00"
	},
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Thursday"
  	],
  	"opens": "11:00",
  	"closes": "18:00"
	},
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Friday"
  	],
  	"opens": "11:00",
  	"closes": "18:00"
	}
  ]
}


  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Wenodo Hospitality Accountants-Dubai",
  "image": "https://www.wenodo.com/app-logo.a6333df9.png",
  "@id": "",
  "url": "https://www.wenodo.com",
  "telephone": "+971 54 320 9768",
  "address": {
	"@type": "PostalAddress",
	"streetAddress": "WARBA CENTER, 2010 Business Center - Al Muraqqabat",
	"addressLocality": "Dubai",
	"postalCode": "",
	"addressCountry": ""
  },
  "openingHoursSpecification": [
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Monday"
  	],
  	"opens": "09:00",
  	"closes": "18:00"
	},
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Tuesday"
  	],
  	"opens": "09:00",
  	"closes": "18:00"
	},
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Wednesday"
  	],
  	"opens": "09:00",
  	"closes": "18:00"
	},
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Thursday"
  	],
  	"opens": "09:00",
  	"closes": "18:00"
	},
	{
  	"@type": "OpeningHoursSpecification",
  	"dayOfWeek": [
    	"Friday"
  	],
  	"opens": "09:00",
  	"closes": "18:00"
	}
  ]
}
{
  "@context": "https://schema.org/", 
  "@type": "BreadcrumbList", 
  "itemListElement": [{
    "@type": "ListItem", 
    "position": 1, 
    "name": "Wenodo : Hospitality Accounting & Software Solutions",
    "item": "https://www.wenodo.com/"  
  },{
    "@type": "ListItem", 
    "position": 2, 
    "name": "Wenodo Hospitality Accountants",
    "item": "https://www.wenodo.com/intelligent-finance"  
  },{
    "@type": "ListItem", 
    "position": 3, 
    "name": "Manage Payroll Services",
    "item": "https://www.wenodo.com/manage-payroll"  
  },{
    "@type": "ListItem", 
    "position": 4, 
    "name": "Hospitality Consulting Services",
    "item": "https://www.wenodo.com/consulting"  
  },{
    "@type": "ListItem", 
    "position": 5, 
    "name": "People Management Software",
    "item": "https://www.wenodo.com/people-management"  
  },{
    "@type": "ListItem", 
    "position": 6, 
    "name": "Power Insights for Hospitality Business",
    "item": "https://www.wenodo.com/power-insight"  
  },{
    "@type": "ListItem", 
    "position": 7, 
    "name": "Book a Demo",
    "item": "https://www.wenodo.com/book-a-demo"  
  },{
    "@type": "ListItem", 
    "position": 8, 
    "name": "Contact-us",
    "item": "https://www.wenodo.com/contact-us"  
  },{
    "@type": "ListItem", 
    "position": 9, 
    "name": "Blog",
    "item": "https://www.wenodo.com/blog"  
  }]
}

`

const scriptTag = document.createElement('script')
scriptTag.type = 'application/ld+json'
scriptTag.textContent = jsonLdData
document.head.appendChild(scriptTag)
